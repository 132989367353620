import { useSelector } from "react-redux"
import { NavPanel } from "../nav-panel/NavPanel"
import { RootRouter } from "../root-router/RootRouter"
import './app-body-styles.css'
import { RootState } from "../../root-redux/RootState"

export const AppBody: React.FC = () => {
    const isNavPanelOpen = useSelector((state: RootState) => state.nav.isNavPanelOpen)
    
    return (
        <div className='app-body'>
            <NavPanel />
            <div className={`root-container ${isNavPanelOpen ? '': 'collapsed'}`}>
                <RootRouter />
            </div>            
        </div>
    )
}