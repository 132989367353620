import { InputFields } from "./input-fields/InputFields";
import { Grid } from "../grid/grid";
import { Spinner, SpinnerBetween } from "../../shared-components/loader/Spinner";
import { NotAuthorizedPage } from "../no-authorization-page/NotAuthorizedPage";
import { authLanguage } from "./JobTitleMappingAttributes";

import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { setEditData, setIsUnmapped, setEditDataJobTitle, clearMappedJobRowData, clearUnmappedJobRowData, clearEditDataJobTitle, setSelectedLanguage, setIsDataToBeEditedFound, clearNothingToShow, clearErrorFetchMessage, clearErrorUploadMessage, clearSuccessMessage, clearError, resetState } from "./job-title-mapping-redux/JobTitleMapping.redux";
import { setAuthorizedLanguagesForUser } from "../../shared-components/user-language-country-data/UserLanguageCountryData.redux";
import { RequestStatus } from '../../model/ServiceRequestStatus.model'

import { Text, Tab, Tabs, TabPanel, Icon, Button, MessageBar, Dialog } from "../../common/HarmonyEnablers";
import { getUnmappedJobTitles } from '../../services/JobTitleMapping/GetUnmappedJobTitles.api';
import { getAuthorizedLanguageData } from "../../services/JobTitleMapping/GetAuthorizedLanguageData.api";
import { getJobTitleMappingData } from "../../services/JobTitleMapping/GetJobTitleMappingData.api";
import { deleteJobTitleMappingSingle } from "../../services/JobTitleMapping/PostDeleteJobTitleMapping.api";
import { exportAllUnmappedJobTitleToExcel } from "../../services/JobTitleMapping/ExportAllUnmappedJobTitleMappingToExcel.api";
import { getExcelFile } from "../../services/JobTitleMapping/GetExcelFile.api";

import "./JobTitleMapping.css";

export const JobTitleMapping: React.FC = () => {

    const [notAuthorized, setNotAuthorized] = useState<boolean>(false);
    const [isPermissionsFetched, setIsPermissionsFetched] = useState<boolean>(false);
    const [createMapping, setCreateMapping] = useState<boolean>(false);
    const [editMapping, setEditMapping] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>("manual");
    const [onCreateRes, setOnCreateRes] = useState<string>("");
    const [onCreateStatus, setOnCreateStatus] = useState<string>("");
    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false);
    const [editDeleteRow, setEditDeleteRow] = useState<any>({});

    const { error, mappedJobRowData, mappedJobColumnData, unmappedJobColumnData, unmappedJobRowData, editData, getUnmappedJobTitlesStatus, isUnmapped, selectedLanguage, getAuthorizedLanguageDataStatus, isDataToBeEditedFound, getJobTitleMappingDataStatus, nothingToShow, errorFetch, errorUpload, successMessage } = useSelector((state: RootState) => state.jobTitleMapping);
    const { authorizedLanguagesForUser } = useSelector((state: RootState) => state.userLanguageCountryData);
    const { isNavPanelOpen } = useSelector((state: RootState) => state.nav);

    const dispatch = useDispatch();
    const dispatchApp = useDispatch<AppDispatch>();

    const divRefForEditManual = useRef<HTMLDivElement>(null);
    const divRefForEditUnmapped = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (activeTab === "unmapped") {
            fetchUnmappedJobTitles();
        }
    }, [selectedLanguage]);

    useEffect(() => {
        if (activeTab === "manual") {
            dispatch(setIsUnmapped(false));
        }
        dispatch(clearErrorFetchMessage());
        dispatch(clearErrorUploadMessage());
        dispatch(clearSuccessMessage());
    }, [activeTab]);

    useEffect(() => {
        var userGroupsFromStorage = window.localStorage.getItem("user_groups");
        if (userGroupsFromStorage) {
            var parsedJSONgroups = JSON.parse(userGroupsFromStorage);
            if (parsedJSONgroups.length > 0 && (parsedJSONgroups.includes("PortalWideUser") || parsedJSONgroups.includes("SuperAdmin"))) {
                dispatch(clearMappedJobRowData());
                dispatch(clearUnmappedJobRowData());
                dispatch(clearNothingToShow());
                fetchAuthorizedLanguage();
            }
            else {
                setNotAuthorized(true);
            }
            setIsPermissionsFetched(true);
        }
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetState());
        };
    }, [dispatch]);

    const handleDelete = async () => {
        var payload = { "jobTitle": mappedJobRowData[0].JobTitle, "languageCode": selectedLanguage.LanguageCode, "languageName": selectedLanguage.Language, "jobRoleData": mappedJobRowData[0].JobRoleInfo, "isUnmapped": isUnmapped }
        await dispatchApp(deleteJobTitleMappingSingle(payload));
        resetMessagesAfterFiveSeconds();
    }

    // API Call to search for job title mapping based on the characters entered in the input field
    const handleEditDelete = async (event: any) => {
        resetErrorMessages();
        event.preventDefault();
        setActiveTab("manual");
        setEditMapping(false);
        var jobTitleForSearch = document.getElementById("jobTitleForSearch") as HTMLInputElement;
        let payload = {
            jobTitle: jobTitleForSearch.value,
            language: selectedLanguage.LanguageCode
        }
        await dispatchApp(getJobTitleMappingData(payload));
        resetMessagesAfterFiveSeconds();
    }

    //API Call to fetch job titles which are unmapped(in the Unmapped tab)
    const fetchUnmappedJobTitles = async () => {
        resetErrorMessages();
        try {
            dispatchApp(getUnmappedJobTitles(selectedLanguage.LanguageCode));
            resetMessagesAfterFiveSeconds();
        } catch (err) {
            return err;
        }
    }

    //API call to fetch the authorized languages for the user
    const fetchAuthorizedLanguage = async () => {
        resetErrorMessages();
        try {
            if (authorizedLanguagesForUser.length === 0) {
                const response = await dispatchApp(getAuthorizedLanguageData());
                if (response.payload.Error) {
                    throw new Error(response.payload.Error);
                }
                else {
                    const authorizedLanguage = response.payload.map((languageData: any) => ({
                        Language: languageData.Language,
                        LanguageCode: languageData.LanguageCode,
                    }));
                    dispatch(setAuthorizedLanguagesForUser(authorizedLanguage));
                    dispatch(setSelectedLanguage(authorizedLanguage[0]));
                }
            }
            else {
                dispatch(setSelectedLanguage(authorizedLanguagesForUser[0]));
            }
            resetMessagesAfterFiveSeconds();
        } catch (err) {
            return err;
        }
    }

    //On click of edit button for the row to be edited open the confirmation dialog box
    const handleDeleteButtonClick = (row: any) => {
        resetErrorMessages();
        setIsOpenDeleteDialog(true);
    }

    //On click of edit button  set the values in the edit window
    const handleEditButtonClick = (row: any) => {
        if (activeTab === 'manual' && divRefForEditManual.current) {
            divRefForEditManual.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (activeTab === 'unmapped' && divRefForEditUnmapped.current) {
            divRefForEditUnmapped.current.scrollIntoView({ behavior: 'smooth' });
        }
        resetErrorMessages();
        dispatch(setIsDataToBeEditedFound(true));
        if (activeTab === "manual") {
            dispatch(setIsUnmapped(false));
            dispatch(setEditDataJobTitle(row.cells.JobTitle));
        }
        else {
            dispatch(setIsUnmapped(true));
            dispatch(setEditDataJobTitle(row.cells.inputJobTitle));
        }
        setCreateMapping(false);
        setEditMapping(true);
        setEditDeleteRow(row);
        setEditData(true);
    }

    const handleDropdownChange = async (e: any) => {
        if (authorizedLanguagesForUser.length > 0) {
            var selectedLang = authorizedLanguagesForUser.filter((item: authLanguage) => item.Language === e.target.value)[0];
            dispatch(setSelectedLanguage(selectedLang));
        }
        return;
    }

    const uploadToExcel = async () => {
        try {
            resetErrorMessages();
            const payload = { language: selectedLanguage.LanguageCode };
            const res = await dispatchApp(exportAllUnmappedJobTitleToExcel(payload));
            const filename = res.payload.fileName;
            var payloadForExcel = { "fileName": filename };
            await dispatchApp(getExcelFile(payloadForExcel));
            resetMessagesAfterFiveSeconds();
        }
        catch (err) {
            return error;
        }
    }

    const handleCloseSuccessMsg = (): void => {
        dispatch(clearSuccessMessage());
    };

    const handleCloseUploadError = (): void => {
        dispatch(clearErrorUploadMessage());
    };

    const handleCloseFetchError = (): void => {
        dispatch(clearErrorFetchMessage());
    };

    const resetErrorMessages = (): void => {
        dispatch(clearError());
        dispatch(clearErrorUploadMessage());
        dispatch(clearErrorFetchMessage());
    };
    const resetMessagesAfterFiveSeconds = (): void => {
        setTimeout(() => {
            dispatch(clearSuccessMessage());
        }, 5000);
    };

    return (
        isPermissionsFetched ?
            notAuthorized ? <NotAuthorizedPage /> :
                <div className={isNavPanelOpen ? "jobtitle-wrapper-collapsed" : "jobtitle-wrapper"}>
                    <div className={isNavPanelOpen ? "jobtitle-create-mapping-collapsed" : "jobtitle-create-mapping"}>
                        <div><Text appearance="heading-2" className="jobtitle-heading">Job Title Mapping</Text></div>
                        <Tabs active-id={activeTab} style={{ marginTop: "10px" }}>
                            <Tab id="manual" onClick={() => { setActiveTab("manual"); dispatch(clearUnmappedJobRowData()); dispatch(setIsUnmapped(false)); setCreateMapping(false); setEditMapping(false); dispatch(setIsDataToBeEditedFound(false)); dispatch(clearNothingToShow()) }} onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    setActiveTab("manual");
                                    dispatch(clearUnmappedJobRowData());
                                    dispatch(setIsUnmapped(false));
                                    setCreateMapping(false);
                                    setEditMapping(false);
                                    dispatch(setIsDataToBeEditedFound(false));
                                    dispatch(clearNothingToShow());
                                }
                            }} >Manual</Tab>
                            <Tab id="unmapped" onClick={() => { setActiveTab("unmapped"); fetchUnmappedJobTitles(); dispatch(clearMappedJobRowData()); dispatch(setIsUnmapped(true)); setCreateMapping(false); setEditMapping(false); dispatch(clearNothingToShow()) }} onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    setActiveTab("unmapped");
                                    fetchUnmappedJobTitles();
                                    dispatch(clearMappedJobRowData());
                                    dispatch(setIsUnmapped(true));
                                    setCreateMapping(false);
                                    setEditMapping(false);
                                    dispatch(clearNothingToShow());
                                }
                            }} >Unmapped</Tab>
                            <TabPanel>
                                <div className={isNavPanelOpen ? "jobtitle-select-language-collapsed" : "jobtitle-select-language"} ref={divRefForEditManual}>
                                    <Text appearance="heading-4" className="jobtitle-heading">Select Language</Text>
                                    <select className="jobtitle-authorized-languages" id="jobtitle-authorized-language-dropdown-manual" value={selectedLanguage.Language} onChange={handleDropdownChange} aria-label="Select Language" aria-labelledby="jobtitle-authorized-language-dropdown-manual">

                                        {authorizedLanguagesForUser?.map(language => (
                                            <option key={language.LanguageCode} aria-selected={language.Language === selectedLanguage.Language ? true : false}
                                                value={language.Language} onClick={handleDropdownChange}
                                            >{language.Language}
                                            </option>
                                        ))
                                        }
                                    </select>
                                    {getAuthorizedLanguageDataStatus === RequestStatus.loading &&
                                        (<div >
                                            <Spinner />
                                        </div>)
                                    }
                                </div>

                                <div >
                                    <Button className="jobtitle-func_btn" appearance="primary" onClick={() => { resetErrorMessages(); setEditMapping(false); setCreateMapping(true); setEditData(false); dispatch(clearEditDataJobTitle()); }}>
                                        <Icon name="contact" slot="start"></Icon>
                                        <Text style={{ color: "white" }}>Create New Mapping</Text>
                                    </Button>
                                    <Button className="jobtitle-func_btn" appearance="primary" onClick={() => { resetErrorMessages(); setEditMapping(true); setCreateMapping(false) }}>
                                        <Icon name="edit" slot="start"></Icon>
                                        <Text style={{ color: "white" }}>Edit / Delete Existing Mapping</Text>
                                    </Button>
                                </div>

                            </TabPanel>
                            <TabPanel>
                                <div className={isNavPanelOpen ? "jobtitle-select-language-collapsed" : "jobtitle-select-language"} ref={divRefForEditUnmapped}>
                                    <Text appearance="heading-4" className="jobtitle-heading">Select Language</Text>

                                    <select className="jobtitle-authorized-languages" id="jobtitle-authorized-language-dropdown-unmapped" value={selectedLanguage.Language} onChange={handleDropdownChange} aria-label="Select Language" aria-labelledby="jobtitle-authorized-language-dropdown-unmapped">
                                        {authorizedLanguagesForUser?.map(language => (
                                            <option key={language.LanguageCode} aria-selected={language.Language === selectedLanguage.Language ? true : false}
                                                value={language.Language} onClick={handleDropdownChange}

                                            >{language.Language}
                                            </option>
                                        ))
                                        }
                                    </select>

                                    {getAuthorizedLanguageDataStatus === RequestStatus.loading &&
                                        (<div >
                                            <Spinner />
                                        </div>)
                                    }
                                </div>
                                <div >
                                    <Button className="jobtitle-func_btn" appearance="primary" onClick={() => { setEditMapping(false); setCreateMapping(true); setEditData(false); dispatch(clearEditDataJobTitle()); }}>
                                        <Icon name="contact" slot="start"></Icon>
                                        <Text style={{ color: "white" }}>Create New Mapping</Text>
                                    </Button>
                                    <Button className="jobtitle-func_btn" appearance="primary" onClick={() => { setEditMapping(true); setCreateMapping(false); dispatch(setIsDataToBeEditedFound(false)) }}>
                                        <Icon name="edit" slot="start"></Icon>
                                        <Text style={{ color: "white" }}>Edit / Delete Existing Mapping</Text>
                                    </Button>
                                </div>

                            </TabPanel>
                        </Tabs>
                        {
                            createMapping && (
                                <>
                                    <Text appearance="heading-3" className="jobtitle-heading">Create Mapping</Text>
                                    <InputFields setCreateMapping={setCreateMapping} setEditMapping={setEditMapping} setOnCreateRes={setOnCreateRes} setOnCreateStatus={setOnCreateStatus} setEditData={setEditData} setIsUnmapped={setIsUnmapped} editData={editData} isUnmapped={isUnmapped} fetchUnmappedJobTitles={fetchUnmappedJobTitles} />
                                </>
                            )
                        }

                        {
                            editMapping && !isDataToBeEditedFound && (
                                <>
                                    <Text appearance="heading-3" className="jobtitle-heading" style={{ marginTop: "10px" }}>Edit / Delete Existing Mapping</Text>
                                    <form className={isNavPanelOpen ? "jobtitle-form-collapsed" : "jobtitle-form"} onSubmit={handleEditDelete}>
                                        <div className={isNavPanelOpen ? "jobtitle-input-fields-collapsed" : "jobtitle-input-fields"}>
                                            <div>
                                                <label htmlFor="jobTitleForSearch">Input Job Title</label>
                                                <input type="text" id="jobTitleForSearch" autoComplete="off" required />
                                            </div>
                                        </div>
                                        <div className="job-title-btn-footer">
                                            <Button appearance="primary" type="submit" className='jobtitle-func_btn'><Icon name="search" slot="start" />Search</Button>
                                            {/* <Button appearance="secondary" onClick={()=>{setCreateMapping(false); setEditMapping(false); setMappedJob(""); setMarketingAudience(""); dispatch(clearJobList())}}><Icon name="clear" slot="start"/>Cancel</Button> */}
                                            <Button appearance="secondary" className='jobtitle-func_btn' onClick={() => { setCreateMapping(false); setEditMapping(false) }}><Icon name="clear" slot="start" />Cancel</Button>

                                        </div>
                                    </form>

                                </>
                            )
                        }
                        {
                            editMapping && isDataToBeEditedFound && (
                                <>
                                    <Text appearance="heading-3" className="jobtitle-heading">Edit / Delete Existing Mapping</Text>
                                    <InputFields setCreateMapping={setCreateMapping} setEditMapping={setEditMapping} setOnCreateRes={setOnCreateRes} setOnCreateStatus={setOnCreateStatus} setEditData={setEditData} setIsUnmapped={setIsUnmapped} editData={true} isUnmapped={isUnmapped} fetchUnmappedJobTitles={fetchUnmappedJobTitles} />
                                </>
                            )
                        }
                    </div>
                    {
                        successMessage &&
                        <div className="jobtitle-success">
                            {successMessage}
                            <Icon name="chromeclose" className="jobtitle-error-close-icon" onClick={handleCloseSuccessMsg} onKeyDown={(e) => { if (e.key === 'Enter') { handleCloseSuccessMsg(); } }} tabIndex={0} />
                        </div>
                    }
                    {
                        errorUpload &&
                        <div className="jobtitle-error">
                            {errorUpload}
                            <Icon name="chromeclose" className="jobtitle-error-close-icon" onClick={handleCloseUploadError} onKeyDown={(e) => { if (e.key === 'Enter') { handleCloseUploadError(); } }} tabIndex={0} />
                        </div>
                    }
                    {
                        errorFetch &&
                        <div className="jobtitle-error">
                            {errorFetch}
                            <Icon name="chromeclose" className="jobtitle-error-close-icon" onClick={handleCloseFetchError} onKeyDown={(e) => { if (e.key === 'Enter') { handleCloseFetchError(); } }} tabIndex={0} />
                        </div>
                    }
                    {getUnmappedJobTitlesStatus === RequestStatus.loading && <div><SpinnerBetween /></div>}
                    {getJobTitleMappingDataStatus === RequestStatus.loading && <div><SpinnerBetween /></div>}
                    {activeTab === "unmapped" && getUnmappedJobTitlesStatus === RequestStatus.succeeded && unmappedJobRowData.length !== 0 && <Grid columnData={unmappedJobColumnData} rowData={unmappedJobRowData} handleEditButtonClick={handleEditButtonClick} page="jobTitleMapping" isExcelUpload={true} uploadToExcel={uploadToExcel} sortType="unmatchCount" isAscending={false} />}
                    {getJobTitleMappingDataStatus === RequestStatus.succeeded && mappedJobRowData.length !== 0 && (<Grid columnData={mappedJobColumnData} rowData={mappedJobRowData} handleDeleteButtonClick={handleDeleteButtonClick} handleEditButtonClick={handleEditButtonClick} page="jobTitleMapping" />)}
                    {nothingToShow !== undefined && (
                        <Text appearance="heading-3">{nothingToShow}</Text>
                    )}

                    <Dialog
                        no-header
                        className="dialog-no-header"
                        open={isOpenDeleteDialog}
                        onHeAfterHide={
                            ({ target, currentTarget }) => (target === currentTarget ? setIsOpenDeleteDialog(false) : null)
                        }
                    >
                        <p>
                            Do you really want to delete this job title mapping?
                        </p>

                        <Button slot="footer" appearance="primary" onClick={() => { setIsOpenDeleteDialog(false); handleDelete() }}>
                            Ok
                        </Button>
                        <Button slot="footer" onClick={() => setIsOpenDeleteDialog(false)}>
                            Close
                        </Button>
                    </Dialog>
                </div>
            :
            <></>
    )
}