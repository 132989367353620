import React from "react";
import { Text } from "../../common/HarmonyEnablers";
import config from "../../config/config-dev";

import "./Home.css";
export const Home: React.FC = () => {
  return (
    <>
      <Text appearance="heading-1" style={{ marginTop: '20px' }}>Welcome to the CDES Portal</Text>
      <br />
      <div style={{ width: "100%", float: "left", maxWidth: "1200px" }}>

        <div style={{ marginBottom: "28px" }}>
          <p style={{ fontSize: "14px", marginTop: "12px" }}>CDES Matches lead data through the portal enrichment and validation process. These services enable data-driven intelligence within the Modern Marketing ecosystem by matching and enriching customer data across Individuals, Organizations and Partners in real time to support the customer journey progression through the marketing and sales process.</p>
          <p style={{ fontSize: "14px", marginTop: "12px" }}>The Data inputs are simple facts about leads: city, company name, email, phone numbers etc. The output from CDES can also be exported for further consumption and analysis.</p>
        </div>

        <span style={{ fontSize: "18px", fontWeight: "500" }}>Get Started</span>
        <div style={{ marginBottom: "28px" }}>
          <p style={{ fontSize: "14px", marginTop: "12px" }}>To get started, select from the available options along the left navigation for Lead Enrichment, Company Mapping, Domain Mapping and Domain Suppression.</p>
        </div>

        <span style={{ fontSize: "18px", fontWeight: "500" }}>Need Access?</span>
        <div style={{ marginBottom: "28px" }}>
                  <p style={{ fontSize: "14px", marginTop: "12px" }}>If you need access to any of the CDES services available on the portal, please send an email to CDES Support  <a href="mailto:%20CDESSupport@microsoft.com?subject=CDES Portal Access Request&amp;body=Hello, Please provide access to CDES Portal for the following user(s) listed below:" style={{ color: "navy", textDecoration: "underline" }}>CDESSupport@microsoft.com</a></p>
        </div>

        <span style={{ fontSize: "18px", fontWeight: "500" }}>GDC Account Matching Report : </span> <a target="_blank" rel="noreferrer" href={config.GDCAccountMatchingReportURL} >PowerBI Report</a>

      </div>
    </>
  );
};
